<template>
    <input
        ref="input"
        class="border-1 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-xs disabled:bg-gray-200 disabled:cursor-not-allowed"
        :value="modelValue"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script setup>
    import { onMounted, ref } from "vue";

    defineProps({
        modelValue: [String, Number],
        placeholder: {
            type: String,
            default: "",
        },
    });

    defineEmits(["update:modelValue"]);

    const input = ref(null);

    onMounted(() => {
        if (input.value.hasAttribute("autofocus")) {
            input.value.focus();
        }
    });

    defineExpose({ focus: () => input.value.focus() });
</script>
